<template>

<div>

	<app-content-box>
		<p>Note - All aspects of the proposed event, including chosen game and time, can be changed at a later time. You can discuss the proposal with the other attendee on the event page.</p>
	</app-content-box>
	
	<app-content-box>
			
		<app-input-text label="Proposal" :autogrow="true" placeholder="Introduce yourself and discuss your proposal..." v-model="model.description" :validation="$v.model.description" />
		<app-input-toggle label="Min. Experience" :slim="true" v-model="model.experience" :validation="$v.model.experience" :options="expOptions" :tooltips="expTooltips" />
		<app-input-toggle label="Are you playing?" v-model="model.seats.host" :validation="$v.model.seats.host" notes="Are you participating in the event as a player and taking a seat." />
		<app-input-toggle label="Total seats" :slim="true" v-model="model.seats.total" :validation="$v.model.seats.total" :options="seatOptions" notes="The total number of players." />
		<app-input-text label="Enter number" v-model="model.seats.totalOther" :validation="$v.model.seats.totalOther" notes="Must be a number between 1 and 1000." v-if="model.seats.total === 1001" />
		<app-input-toggle label="Reserved seats" :slim="true" v-model="model.seats.blocked" :validation="$v.model.seats.blocked" :options="blockOptions" notes="The number of seats already reserved for other people not on this system." v-if="model.seats.total <= 8" />
		<app-input-text label="Reserved seats" v-model="model.seats.blockedOther" :validation="$v.model.seats.blockedOther" notes="Must be a number between 0 and 1000." v-if="model.seats.total > 8 && model.seats.total !== 1002" />

	</app-content-box>

	<app-button text="Confirm" :disabled="!isValid" v-on:click="onSaveClick" :loading="is.saving" />

</div>

</template>

<script>

import mixForm from '@/mixin/form'
import Vue from 'vue'
import { required, minValue } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	props: ['proposal'],

	data: function() {

		return {
			seatOptions: {
				1: 1,
				2: 2,
				3: 3,
				4: 4,
				5: 5,
				6: 6,
				7: 7,
				8: 8,
				1001: 'Other',
				1002: 'Unlimited'
			},
			model: {
				proposal: {},
				description: '',
				experience: 0,
				seats: {
					host: 1,
					total: 2,
					totalOther: 0,
					blocked: 0,
					blockedOther: 0
				}
			}
		}

	},

	validations: {
		model: {
			description: {
				required
			},
			seats: {
				total: {
					required,
					minValue: minValue(1)
				}
			}
		}
	},

	created: function() {

		Vue.set(this.model, 'proposal', this.proposal)

	},

	computed: {

		isNew: function() {

			return true

		},

		expOptions: function() {

			return this.$constants.schedule.experienceShort

		},

		expTooltips: function() {

			return this.$constants.schedule.experienceTooltip

		},

		blockOptions: function() {

			var seats = {}
			var total = (this.model.seats.total > 8) ? this.model.seats.totalOther || 8 : this.model.seats.total || 8

			for (var i = 0; i <= total; i++) {

				seats[i] = i

			}

			return seats
		}

	},

	methods: {

		onSaved: function() {

			this.$router.push({
				name: 'Convention.Schedule.Event',
				params: {
					id: this.model.id
				}
			})

		}

	}

}

</script>

<style scoped>

</style>